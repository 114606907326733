@import "../../common/css/common";

.ca-form-checkbox {
    width: 14px;
    height: 14px;

    .ca-form-checkbox__label {
        display: inline;
    }

    .ca-form-checkbox__input {
        display: block;
        width: 100%;
        margin-bottom: 10px;

        position: absolute;
        left: -9999px;

        & + label {
            @include transition();
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            font-size: 14px;
            color: $form-group-label-color;
        }

        &:hover + label {
            color: $blue;
        }

        /* checkbox aspect */
        & + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 14px;
            height: 14px;
            margin-top: -8px;
            border: 1px solid $checkbox-border-before;
            background: $white;
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
            box-sizing: content-box;
        }

        &:checked + label:before {
            border: 1px solid $blue;
        }

        /* checked mark aspect */
        & + label:after {
            content: '✔';
            position: absolute;
            top: 50%;
            left: 1px;
            margin-top: -7px;
            text-align: center;
            width: 14px;
            height: 14px;
            line-height: 0.8;
            color: $white;
            transition: all .1s;
            background: $checkbox-blue-active;
        }

        /* checked mark aspect changes */
        &:not(:checked) + label:after {
            opacity: 0;
        }

        &:checked + label:after {
            opacity: 1;
        }

        /* disabled checkbox */
        &:disabled + label:before {
            box-shadow: none;
            border-color: $checkbox-border-disabled;
        }

        &[disabled] + label:before {
            box-shadow: none;
            border-color: $checkbox-border-disabled;
        }

        &:disabled:checked + label:after {
            color: $white;
            background-color: $checkbox-border-disabled;
        }

        &:disabled + label {
            color: $checkbox-txt-disabled;
            cursor: not-allowed;
        }

        &:disabled + label:hover {
            color: $checkbox-txt-disabled;
        }

        &:disabled + label:hover:before {
            border: 1px solid $checkbox-border-disabled !important;
        }

        /* accessibility */
        &:checked:focus + label:before,
        &:not(:checked):focus + label:before {
            border: 1px solid $checkbox-blue-active;
        }

        /* hover style just for information */
        [type="checkbox"] + label:hover:before {
            border: 1px solid $checkbox-blue-active !important;
        }
    }
}

.ca-form-checkbox--labeled {
    width: inherit;
    height: inherit;
    margin-bottom: 20px;
}
