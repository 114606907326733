@import "../../common/css/common";

.ca-form-input {
    @include transition();
    height: 27px;
    width: 100%;
    padding: 0 10px;
    color: $text-regular;
    font-family: $open-sans;
    font-size: 14px;
    border: 1px solid $input-border-before;
    min-width: 0;
    min-height: 0;

    &:focus:enabled, &:hover:enabled {
        border-color: $input-border-active;
        outline: 0;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: $input-border-disabled;
        border-color: $input-border-before;
    }
}
