@import "../../common/css/common";

.ca-grid__row {
    @include flexbox();
    margin-right: -15px;
    margin-left: -15px;

    @include respond($to-tablet) {
        @include flex-flow(column);
    }

    &--footer {
        margin-top: 20px;
    }
}

.ca-grid__col {
    @include flex(1);
    padding-right: 15px;
    padding-left: 15px;

    &--inline {
        display: flex;
        justify-content: space-between;
    }
}


