@import "src/ca-common/ui-lib/common/css/common";

.fileInput {
    height: 24px;
    border-radius: 12px;
    border: 1px solid $dusky-blue;
    display: inline-block;
    font-size: 14px;
    padding: 0 15px;
    color: $dusky-blue;
    cursor: pointer;
    &:hover {
        color: $hover-blue;
        border-color: $hover-blue;
    }
    &:active {
        color: $click-blue;
        border-color: $click-blue;
    }
}

.fileName {
    color: $dusky-blue;
    list-style: none;
    li {
        margin-bottom: 5px;
        @include flexbox();
        @include align-items(center);
    }
    span {
        padding-right: 6px;
        font-size: 14px;
        color: $dusky-blue;
        line-height: 17px;
    }
    .anticon {
        font-size: 9px;
        cursor: pointer;
    }
    .anticon.icon {
        font-size: 17px;
    }
}
