@import "~ca-common/ui-lib/common/css/common";

.ca-form-page {
    color: $text-regular;

    .ca-form-page__footer {
        @include flexbox();
        @include justify-content(flex-end);
        margin: 20px 0;
        gap: 8px;
        padding-top: 12px;
        border-top: 1px solid $gray-light;

        button {
            align-self: flex-end;
            margin-left: 10px;
            margin-top: 10px;
        }
    }

    &__section-title {
        line-height: 34px;
        font-size: 14px;
        font-weight: 700;
        color: $text-regular;
    }

    &__section--error-status {
        p {
            color: $red;
        }
    }

    &__error {
        width: 100%;
        color: $red;
    }
}
