@import "../../../common/css/common";

.ca-form-input {
    @include flexbox();
    @include flex(1);
    @include transition();
    height: 27px;
    width: 100%;
    padding: 0 10px;
    color: $text-regular;
    font-family: $open-sans;
    font-size: 14px;
    border: 1px solid $input-border-before;
    min-width: 0;
    min-height: 0;

    &:focus:enabled, &:hover:enabled {
        border-color: $input-border-active;
        outline: 0;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: $input-border-disabled;
        border-color: $input-border-before;
    }
}

.ca-form-group__label {
    @include flexbox();
    @include flex(0 0 35%);
    @include align-items(center);
    font-size: 14px;
    font-weight: 400;
    color: $form-group-label-color;
    padding-left: 10px;

    @include respond($to-phone) {
        @include flex(1);
        margin-bottom: 8px;
    }
}

.ca-form-group__label--required {
    &:before {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        color: $red;
    }
}

.ca-form-group__error{
    position: absolute;
    top: 27px;
    font-size: 10px;
    color: $red;

    @include respond($to-phone) {
        top: inherit;
        left: 0;
        bottom: -16px;
    }
}


.ca-form-group--labeled {
    @include align-items(center);

    .ca-form-group__error{
        left: 35%;
    }

    .ca-form-input--error {
        margin-bottom: 9px;
    }
}

.ca-form-group--q3LMEFjzJs {
    .ca-form-group__error{
        left: initial;
    }
}
