@import "src/ca-common/ui-lib/common/css/common";

.ca-billing-page {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-top: 14px;

    &__link {
        text-decoration-color: transparent;
        color: $text-regular;
    }
}
