@import "../../../ui-lib/common/css/common";

.ca-btn {
    @extend .ca-transitional;
    padding: 0 14px;
    border: 1px solid $blue;
    @include border-radius(12px);
    font-family: $open-sans;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    box-shadow: 2px 3.5px 9.1px 0.9px $button-shadow;
}

.ca-btn--primary {
    color: $white;
    background: $blue;
    font-weight: 700;
}

.ca-btn--secondary {
    color: $blue;
    background: $white;
}

.ca-btn--info{
    color: $white;
    background: $gray;
    border-color: $gray;
}

.ca-btn--modal-footer {
    margin-right: 12px;
}
