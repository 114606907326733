@import "~ca-common/ui-lib/common/css/common";

.ca-form-radio {
    position: relative;
    width: 14px;
    height: 14px;

    &__input {
        @include transition();
        position: absolute;
        visibility: hidden;
    }

    &__check {
        @include transition();
        display: block;
        position: absolute;
        opacity: 0.8;
        border: 1px solid $radio-border-before;
        @include border-radius(10px);
        height: 14px;
        width: 14px;
        top: 3px;
        left: 0;
        z-index: 5;

        &:before {
            @include transition();
            display: block;
            position: absolute;
            content: '';
            @include border-radius(10px);
            height: 8px;
            width: 8px;
            top: 2px;
            left: 2px;
            margin: auto;
        }
    }
}

.ca-form-radio:hover {
    cursor: pointer;

    .ca-form-radio__check {
        border: 1px solid $blue;
    }
}


input[type="radio"].ca-form-radio + label {
    @include transition();
    display: block;
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    margin-right: 8px;
    z-index: 9;
    cursor: pointer;
}

input[type="radio"].ca-form-radio:hover + label {
    color: $blue;
}

.ca-radio:hover .ca-radio-check {
    opacity: 1;
}

.ca-form-radio__input:checked ~ .ca-form-radio__check {
    opacity: 1;
    border: 1px solid $blue;
}

.ca-form-radio__input:checked ~ .ca-form-radio__check::before {
    background: $blue
}

.ca-form-radio__input:checked ~ label {
    color: $blue
}

.ca-form-radio__input:disabled ~ label {
    color: $checkbox-txt-disabled;
    cursor: not-allowed;
}

.ca-form-radio__input:disabled ~ .ca-form-radio__check {
    border-color: $checkbox-txt-disabled;
}
