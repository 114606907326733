@import "src/ca-common/ui-lib/common/css/common";

.ca-users-page {
    .ca-users {
        &__actions {
            @include flexbox();
            @include justify-content(flex-end);
            margin-bottom: 20px;

            a {
                text-decoration: none;
                @include flexbox();
                align-items: center;

                .new-user {
                    font-family: $open-sans;
                    font-size: 16px;
                    margin-left: 8px;
                }
            }
        }

        &__text {
            color: $text-regular;

            h2 {
                font-size: 18px;
                color: $text-color;
                font-weight: 400;
                margin-top: 25px;
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }
        }

        .ca-btn {
            margin-left: 10px;
        }
    }

    .ca-table__row {
        .row-actions {
            .ca-icon {
                margin-right: 14px;
            }
        }

        &:hover {
            .ca-table__row-indicator {
                background: $blue-light;
            }
        }

        &--warning:hover {
            .ca-table__row-indicator {
                background: rgba(242, 153, 74, 0.41); // it's variables.orange, but there is not this color in _color.scss
            }
        }

        .ca-icon--fa-authentication {
            font-size: 27px;
        }
    }
}
