@import "~ca-common/ui-lib/common/css/common";

.react-select {
    flex: 1;
    min-width: 80px;
    position: relative;

    &--is-disabled {
        pointer-events: initial !important;
        cursor: not-allowed;
    }

    .react-select__indicator {
        padding: 0 8px;
    }
    .react-select__indicator-separator {
        display: none;
    }

    .react-select__control {
        display: flex;
        position: relative;
        height: 26px;
        min-height: 26px;
        width: 100%;
        color: $text-regular;
        cursor: default;
        border: 1px solid $gray;
        border-spacing: 0;
        border-collapse: separate;
        border-radius: 0;
        outline: none;
        overflow: hidden;
        background-color: $white;
        @include box-shadow(0, 1px, 0, rgba(0, 0, 0, 0.06));

        &:hover {
            border-color: $gray;
            cursor: pointer;
        }

        &--is-disabled {
            background-color: $white-grey;
            opacity: .5;
            pointer-events: none;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    .react-select__value-container {
        padding: 0 8px;
    }

    .react-select__input {
        display: block !important;
    }

    .react-select__single-value + div,  
    .react-select__value-container--has-value > div {
        padding: 0;
        margin: 0;
    }

    .react-select__single-value, .react-select__placeholder, .react-select__input {
        color: $placeholder;
        font-size: 14px;
    }

    .react-select__value-container--has-value {
        .react-select__single-value, .react-select__input {
            color: $text-regular;
        }
    }

    .react-select__menu {
        margin-top: 0;
        min-width: 100%;

        .react-select__menu-list {
            padding-bottom: 0;
            padding-top: 0;
        }

        .react-select__option {
            padding: 4px 10px;
            font-size: 14px;

            &:hover {
                cursor: pointer;
            }

            &--is-selected {
                background: $blue-light;
                color: $white;
            }

            &--is-focused {
                background: $blue;
                color: $white;
            }
        }
    }
}
