@import "~ca-common/ui-lib/common/css/common";

.ca-form-group--radio-group {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .ca-form-group__label {
        flex: 1 0 auto;
        font-weight: 600;
        margin-bottom: 12px;
    }

    & :last-child {
        margin-bottom: 0;
    }

    .ca-form-group__radio-wrapper {
        margin-left: 10px;
        margin-bottom: 12px;

        .ca-form-group__radio::after {
            background: $navy-blue;
        }

        .ca-form-group__radio::before {
            border-color: $navy-blue;
        }
    }

    &.ca-form-group--inline {
        flex-direction: row;
         .ca-form-group__label {
             margin-bottom: 0;
             font-weight: normal;
         }
        .ca-form-group__radio-wrapper {
            margin-bottom: 0;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
