@import "~ca-common/ui-lib/common/css/common";

.ca-form-group__radio-wrapper {
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .ca-tooltip {
        right: -24px;
        top: 0;
        position: absolute;
    }

    &>input {
        margin-right: 5px;
    }

    input[type="radio"] {
        position: absolute;
        left: -9999px;

        & + label
        {
            font-size: 14px;
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            display: inline-block;
            color: $text-regular;
            font-weight: normal;
        }

        & + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 14px;
            height: 14px;
            border: 1px solid $gray;
            border-radius: 100%;
            background: $white;
            box-sizing: content-box;
        }

        & + label:after {
            content: '';
            width: 8px;
            height: 8px;
            background: $checkbox-blue-active;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            transition: all 0.2s ease;
        }

        &:not(:checked) + label:after {
            opacity: 0;

            @include transform(scale(0));
        }

        &:checked + label:after {
            opacity: 1;
            @include transform(scale(1));
        }

        &:disabled + label:before {
            box-shadow: none;
            border-color: $checkbox-border-disabled;
        }


        &:disabled:checked + label:after {
            color: $white;
            background-color: $checkbox-border-disabled;
        }

        &:disabled + label {
            color: $checkbox-txt-disabled;
            cursor: not-allowed;
        }

        &:disabled + label:hover {
            color: $checkbox-txt-disabled;
        }

        &:disabled + label:hover:before {
            border: 1px solid $checkbox-border-disabled !important;
        }

        &:focus + label:before {
            border: 1px solid $checkbox-blue-active;
        }

        & + label:hover:before {
            border: 1px solid $checkbox-blue-active !important;
        }
    }
}
