@import "~ca-common/ui-lib/common/css/common";

.ca-menu-linked-item {
    @include flexbox();
    height: 60px;
    background: $side-menu-bg-regular;

    a {
        @include transition();
        @include flexbox();
        @include align-items(center);
        width: 100%;
        text-transform: uppercase;
        color: $side-menu-txt-regular;
        text-decoration: none;

        &:hover {
            color: $side-menu-txt-hover;
            background: $side-menu-bg-hover;

            .ca-icon {
                opacity: 0.7;
            }
        }
    }

    a.active-menu-item {
        color: $side-menu-txt-selected;
        background-color: $side-menu-bg-selected;

        &:hover {
            color: $side-menu-txt-hover;
        }

        .ca-icon {
            color: $side-menu-txt-selected;
        }
    }

    &:focus {
        outline: 0;
    }

    &.is-disabled,
    &.is-disabled .ca-icon {
        pointer-events: none;
    }

    .ca-menu-linked-item__title {
        @include flexbox();
        @include align-items(center);
        font-size: 14px;
        padding-left: 5px;
        margin-bottom: 0;
    }

    .ca-icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        width: 60px;
    }
}

.SubmenuPopup {
    .ant-menu.ant-menu-vertical .ant-menu-item {
        margin: 0;
        padding: 0 32px 0 16px;
        display: flex;
        align-items: center;
        height: 72px;
        background-color: #F4F4F9;

        &.ant-menu-item-selected {
            background-color: $sub-menu-bg-selected!important;
            color: $navy-blue;
            &:hover {
                a {
                    color: $navy-blue;
                }
            }
        }
        &:hover {
            background-color: $sub-menu-bg-hover;
            a {
                color: $side-menu-txt-selected;
                text-decoration: none;
            }
        }
        &:active {
            background-color: $sub-menu-bg-active;
            a {
                color: $navy-blue;
            }
        }
    }
    .ant-menu-sub {
        border-radius: 4px;
    }
}
