@import "src/ca-common/ui-lib/common/css/common";

html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: $open-sans;
    background: $site-bg-color;
}

.ca-page {
    .ca-page-content-wrapper {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }
    .ca-page-side-menu {
        flex: none;
        overflow-y: auto;
    }
    .ca-page-content {
        flex: 1 1 auto;
        background: $site-bg-color;
        padding: 23px 19px 0;
        position: relative;
        width: 100%;
    }
}
