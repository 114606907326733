@import "flexbox";

$open-sans: 'Open Sans', sans-serif;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition() {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    transition: all 300ms ease;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@mixin shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

.ca-body {
    height: 100%;

    font-size: 62.5%;
    font-family: $open-sans;
}

.ca-link {
    @extend .ca-transitional;
    font-size: 1.2em;
    font-weight: 700;
    text-decoration: underline;
    color: $blue;
}

.ca-transitional {
    @include transition();
    opacity: 0.9;
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:focus {
        outline: 0;
    }

    &:active {
        opacity: 1;
    }
}

.ca-clear {
    clear: both;
}

.ca-wrapper {
    width: 800px;
    margin: 0 auto;
}

.logout {
    @extend .ca-transitional;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    color: $text-regular;
    text-decoration: none;

    &:hover, &:focus {
        color: $blue;
        .icon {
            color: $blue;
        }
    }

    &:focus {
        font-weight: 700;
    }

    .icon {
        font-size: 1.5em;
        top: 5px;
        left: 10px;
    }
}

.add-new {
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    display: block;
    text-decoration: none;

    .icon {
        top: 9px;
        right: 6px;
        color: $blue;
    }
    span {
        margin-left: 7px;
    }
}

.ca-form-group {
    margin-bottom: 20px;
}

.ca-form-group, .ca-form-checkbox {
    display: flex;
    position: relative;

    @include respond($to-phone) {
        @include flex-flow(column);
    }
}

.ca-form-group--vertical {
    flex-direction: column;
    align-items: flex-start;

    .ca-form-group__label {
        padding-left: 0;
        flex: 1 0 auto;
    }

    .ca-form-input {
        flex: 1 0 auto;
    }
}

@mixin transform($deg) {
    -moz-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}
