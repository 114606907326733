@import "src/ca-common/ui-lib/common/css/common";

.ca-form-group {

    &__error {
        &--textarea {
            position: initial;
            top: auto;
        }
    }

    &__textarea {
        @include transition();
        width: 100%;
        padding: 5px 10px;
        color: $text-regular;
        font-family: $open-sans;
        font-size: 14px;
        border: 1px solid $input-border-before;
        resize: none;

        &:focus:enabled, &:hover:enabled {
            border-color: $input-border-active;
            outline: 0;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-color: $input-border-disabled;
            border-color: $input-border-before;
        }
    }

    &--textarea {
        flex-direction: column;
    }
}
