@import "~ca-common/ui-lib/common/css/common";

.ca-form-group {
    &__label {
        @include flexbox();
        @include flex(0 0 35%);
        @include align-items(flex-start);
        font-size: 14px;
        font-weight: 400;
        color: $form-group-label-color;

        @include respond($to-phone) {
            @include flex(1);
            margin-bottom: 8px;
        }
    }

    &__error{
        position: absolute;
        top: 27px;
        left: 35%;
        font-size: 10px;
        color: $red;

        @include respond($to-phone) {
            top: inherit;
            left: 0;
            bottom: -16px;
        }
    }
}
