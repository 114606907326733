@import "~ca-common/ui-lib/common/css/common";

.ca-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    list-style-type: none;
    font-size: 14px;
    color: $page-nav-breadcrumbs-color;
    text-decoration: none;

    li {
        display: flex;

        a {
            color: $page-nav-breadcrumbs-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        span:not(:first-child) {
            margin: 0 4px;
        }
    }
}
