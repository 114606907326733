// @use 'ca-common/variables' as variables;
@import 'src/ca-common/ui-lib/common/css/common';

.ca-status-page {
    font-family: 'Lato', 'Helvetica', sans-serif;

    h2 {
        margin-bottom: 20px;
    }

    .ca-icon--action-exclamation {
        font-size: 17px;
        margin-right: 10px;
        color: $red;
        float: left;
        height: 100%;
        margin-top: 3px;
    }

    .ca-icon--action-exclamation.ca-icon--green {
        @include flexbox();
        @include align-items(center);
        color: $green;
        margin-top: 0px;
    }

    .ca-alert {
        display: block;
        padding: 20px;
    }

    .ca-btn a {
        font-weight: 700;
        color: $white;
        text-decoration: none;
    }

    .ca-grid__col:last-child {
        text-align: right;
    }

    .ca-grid__row {
        position: relative;
    }

    .ca-grid__row::before {
        border-bottom: 1px solid #d9dfe1;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid #d9dfe1;
        width: 95%;
        margin-left: 1.75%;
    }

    .ca-grid__col {
        margin-right: 50px;
    }
}

.selectedButton {
    background: $white;
    color: $navy-blue;
}

.unselectedButton {
    background: $navy-blue;
    color: $white;
    border: 1px solid $sub-menu-bg-active;
}

.lastButton {
    margin-left: -20px !important;
}
