@import "~ca-common/ui-lib/common/css/common";

.ca-table-wrapper {
    display: flex;
    flex-flow: column;
}

.ca-table {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include transition();

    font-family: $open-sans;
    color: $text-regular;
    background-color: $white;

    text-align: left;
    width: 100%;
    overflow: hidden;

    .ca-icon {
        @include transition();
    }

    &__body--blue-stage {
        .ca-table__row:nth-child(odd) {
            background-color: $blue-table-background;

            .ca-table__cell {
                background-color: $blue-table-background;
            }
        }

        .ca-table__row--item:hover {
            background-color: rgba(220, 220, 220, 0.38);

            .ca-table__cell {
                background-color: inherit;
            }
        }
    }

    .ca-table__row {
        @include flexbox();
        @include flex-flow(row nowrap);
        min-height: 40px;

        font-size: 12px;
        font-weight: 400;

        &--disabled {
            pointer-events: none;

            .ant-checkbox .ant-checkbox-inner {
                filter: contrast(0.3);
            }
        }

        .ca-btn-more {
            @extend .ca-transitional;

            font-size: 36px;
            cursor: pointer;
            color: #5280b8;
        }
        .ca-btn-more:hover {
            opacity: 0.6;
        }

        .ca-table__cell {
            min-height: 40px;
            @include flexbox();
            @include flex(1);
            @include flex-flow(row nowrap);
            @include align-items(center);
            cursor: default;
            word-break: break-word;

            a {
                color: $blue-light;

                &:hover {
                    color: $ice-blue;
                }
            }

            p {
                margin-bottom: 0;
            }
            &--link {
                a {
                    color: $navy-blue;
                    font-weight: bold;
                    &:hover {
                        text-decoration: underline;
                        color: $navy-blue;
                    }
                }
            }
        }

        .ca-table__cell--truncate {
            overflow: hidden;
            a, p, span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .ca-table__cell--first {
            padding-left: 25px;
        }

        .ca-table__cell--no-padding {
            padding: 0;
        }

        .ca-table__cell--no-left-padding {
            padding-left: 0;
        }

        .ca-table__cell--title {
            font-size: 20px;
            margin: 20px 0px 10px 0;
        }

        .ca-table__cell--center {
            @include justify-content(center);
        }

        .ca-table__cell--right {
            @include justify-content(flex-end);
        }

        .ca-table__cell--selection {
            padding: 0 10px 0 20px;
        }

        .ca-table__cell--700px {
            @include flex(0 0 700px);
        }

        .ca-table__cell--500px {
            @include flex(0 0 500px);
        }

        .ca-table__cell--400px {
            @include flex(0 0 400px);
        }

        .ca-table__cell--300px {
            @include flex(0 0 300px);
        }

        .ca-table__cell--260px {
            @include flex(0 0 260px);
        }

        .ca-table__cell--240px {
            @include flex(0 0 240px);
        }

        .ca-table__cell--180px {
            @include flex(0 0 180px);
        }

        .ca-table__cell--200px {
            @include flex(0 0 200px);
        }

        .ca-table__cell--180px {
            @include flex(0 0 180px);
        }

        .ca-table__cell--150px {
            @include flex(0 0 150px);
        }

        .ca-table__cell--130px {
            @include flex(0 0 130px);
        }

        .ca-table__cell--100px {
            @include flex(0 0 100px);
        }

        .ca-table__cell--80px {
            @include flex(0 0 80px);
        }

        .ca-table__cell--60px {
            @include flex(0 0 60px);
        }

        .ca-table__cell--40px {
            @include flex(0 0 40px);
        }

        .ca-table__cell--30px {
            @include flex(0 0 30px);
        }

        .ca-table__cell--10px {
            @include flex(0 0 10px);
        }

        .ca-table__cell--wrap {
            @include flex(0 0 auto);
        }

        .ca-table__cell--pointer {
            cursor: pointer;
        }

        .ca-btn {
            margin-right: 15px;
        }
    }

    .ca-table__body {
        .ca-table__row {
            display: flex;
            flex-flow: column;
        }
    }

    .ca-table__row--header {
        border-bottom: 1px solid $vertical-line-color;
        font-size: 14px;
        font-weight: 600;
    }
    .ca-table__row--sub-header {
        font-size: 12px;
        font-weight: 600;
    }
    .ca-table__row--item:hover,
    .ca-table__row--folder:hover {
        background-color: rgba(220, 220, 220, 0.38);
    }
    .ca-table__row--footer {
        @include align-items(center);
        @include justify-content(center);
        position: relative;
        border-top: 1px solid $vertical-line-color;
        border-bottom: 1px solid $vertical-line-color;
        span {
            padding: 6px;
        }
    }
    .ca-table__row:nth-child(odd) {
        background-color: rgba(98, 144, 200, 0.03);
    }
    .ca-table__row--loading {
        margin: 100px auto;

        .ca-spinner {
            margin: auto;
        }
    }
    .ca-table__row--center {
        @include justify-content(center);
    }

    .ca-table__row-items {
        display: flex;
    }

    .ca-table__row-indicator {
        position: absolute;
        width: 4px;
        min-height: 40px;
        transition: all 0.1s;
    }

    .ca-table__row--error {
        .ca-table__row-indicator {
            background: $red-light;
        }
    }

    .ca-table__row--info {
        .ca-table__row-indicator {
            background: $blue-light;
        }
    }

    .ca-table__row-collapse {
        padding: 0 60px;

        p {
            line-height: 24px;
        }
    }

    .ca-table__current-page {
        width: 30px;
        margin: 0 6px;
        background-color: white !important;
        text-align: center;
        border-radius: 5px;
        cursor: default !important;
        border-style: groove;
        font-size: 12px;
        font-family: $open-sans;
        color: $text-regular;
    }

    .ca-table__page-control {
        margin: 0px;
    }

    .ca-table__pagination-info {
        position: absolute;
        right: 55px;
        top: 5px;
    }
}

.ca-table--parent {
    @include box-shadow(0, 0, 12px, rgba(0, 0, 0, 0.1));
    margin-bottom: 20px;
}

.ca-table--scrollable-old {
    margin-bottom: 20px;
    .inner-table {
        @include box-shadow(0, 0, 12px, rgba(0, 0, 0, 0.1));
        height: 100%;
        overflow-y: auto;
        height: auto;
        max-height: 560px;
        &::-webkit-scrollbar {
            background-color: #fff;
            width: 16px
        }

        &::-webkit-scrollbar-track {
            background-color: #fff
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e1e6ea;
            @include border-radius(16px);
            border: 5px solid #fff;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #b8b8be;
        }

        &::-webkit-scrollbar-button {
            display: none
        }
    }
}

.ca-table--scrollable {
    max-height: 100%;

    .ca-table__cell {
        padding: 0 20px;
    }

    .ca-table__row--header, .ca-table__scrollable-wrapper {
        &::-webkit-scrollbar {
            background-color: $white;
            width: 16px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            background-color: $white
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius(16px);
            background-color: $scrollbar-thumb-bg;
            border: 5px solid $white;
            opacity: 0.8;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $scrollbar-thumb-bg-hover;
        }

        &::-webkit-scrollbar-button {
            display: none
        }
    }

    .ca-table__row--header {
        overflow: inherit;
        margin-bottom: -1px;

        &::-webkit-scrollbar-thumb {
            background-color: $white;
        }
    }

    .ca-table__scrollable-wrapper {
        position: relative;
        border-top: 1px solid $vertical-line-color;
        overflow: auto;
        background: $white;
    }
}

.ca-cell-modifier {
    display: flex;
    align-items: center;

    &--warning {
        color: $red-light;
        font-weight: 600;

        .ca-icon {
            margin-right: 6px;
            color: $red-light;
            font-size: 14px;
        }
    }

    &--info {
        color: $dusky-blue;
        font-weight: 600;

        .ca-icon {
            margin-right: 6px;
            color: $dusky-blue;
            font-size: 14px;
        }
    }

    &--recovery-type, &--recovery-status {
        .ca-icon {
            font-size: 17px;
            margin-right: 8px;
            color: $blue-gray;
        }
    }

    &--recovery-actions {
        justify-content: center;
        margin: 0 -6px;

        .ca-icon {
            padding: 0 6px;
            font-size: 17px;
            color: $blue-light;
            opacity: 0.8;

            &:hover {
                cursor: pointer;
                opacity: 1;
            }

            &--arrow-right {
                font-size: 8px;
            }
        }
    }

    &--centered {
        width: 100%;
        justify-content: center;
    }

    &--ellipsis {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--max90 {
        max-width: 90px;
    }

    &--max200 {
        max-width: 200px;
    }
}

.table-actions {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0 20px;

        a {
            text-decoration: none;
        }

        .ca-icon--new-backup {
            display: flex;
            align-items: center;
        }
    }

    &__link {
        font-family: $open-sans;
        font-size: 16px;
        margin-left: 8px;
    }

    &__title {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
    }
}

.ca-select-all {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 36px;
    margin-bottom: 8px;
    font-size: 14px;

    p {
        color: $text-regular;
    }

    .ca-link {
        font-size: 14px;
        font-weight: 500;
    }
}
