$white-grey: #f5f5f5;
$white: #ffffff;
$blue: #262D61;
$blue-light: #262D61;
$navy-blue: #202880;
$blue-table-background: #fbfcfe;
$dusky-blue: #5163a8;
$hover-blue: #697bc2;
$black: #292728;
$gray: #b0bbc6;
$gray-light: #dae1e8;
$gray-dark: #c1c9d1;
$red-light: #E95353;
$red-broken: #ef4836;
$red: #ff3322;
$green: #03BF9C;
$blue-gray: #7b8da0;
$light-blue-gray: #738f9d;
$click-blue: #3c4f9b;
$faded-blue: #fafcfd;
$dark-slate-blue: #272a64;
$warm-grey: #757575;
$cool-grey: #afb1b1;
$yellow-orange: #edad02;
$ice-blue: #00c7ff;

$text-color: #33464f;

$icon-gray: $gray-dark;
$icon-blue: $blue-light;

$text-regular: #34474f;
$placeholder: #aaaaaa;

$side-menu-txt-regular: $gray;
$side-menu-bg-regular: #2c2c2c;
$side-menu-txt-hover:  $gray;
$side-menu-bg-hover: #3b425e;
$side-menu-txt-selected: $white;
$side-menu-bg-selected: #4a588f;

$sub-menu-bg-selected: #E9EAF2;
$sub-menu-bg-hover: #1d2473;
$sub-menu-bg-active: #D2D4E6;

/*Scroll bar*/
$scrollbar-thumb-bg: #e5e8ec;
$scrollbar-thumb-bg-hover: #b0bbc6;
/*End scrollbar*/

/*Form group*/
$form-group-label-color: #33464f;
$input-border-before: $gray;
$input-border-active: #7b8da0;
$input-border-disabled: $white-grey;

$select-bg-hover: #6290c8;
$select-border-before: $gray;

$checkbox-border-before: $gray;
$checkbox-border-disabled: #d1d7dd;
$checkbox-blue-active: #6290c8;
$checkbox-txt-disabled: #d1d7dd;

$radio-border-before: #7b8da0;
/*End Form group*/

$breadcrumbs: $text-regular;

$header-border: rgba(0, 0, 0, 0.1);
$task-text-color: #33464f;
$task-account-color: #73909d;
$task-succeed-color: #0cc2aa;
$task-broken-color: $red-broken;
$task-border-hover-color: #72A0D8;
$task-border-focus-color: $blue;

$vertical-line-color: $gray-light;

$header-text-color: $text-regular;

$shadow-color: rgba(75, 75, 75, 0.1);

$toast-title-success: #0cc2aa;
$toast-title-error: #f26f75;
$toast-title-info: $dusky-blue;
$toast-title-warning: #393939;
$toast-box-shadow: #BBBBBB;
$toast-text-color: $text-regular;

$modal-text-color: #33464f;
$modal-error-title-bg: #f26f75;
$modal-warning-title-bg: #f3ad07;

/*Partner Portal styles*/
$client-account-main-color: #33464f;
$client-account-sub-color: #73909d;
$client-account-border-color: #dae1e8;
$client-account-box-shadow: #e1e1e1;
/*End Partner Portal styles*/

/*Icon colors*/
$icon-green-color: #0cc2aa;
$icon-common-color: #7b8da0;
$icon-red-color: #e8594a;
$icon-disabled: #d1d7dd;
/*End Icon colors*/

/*Jumbotron*/
$jumbotron-header-bg: #eff6ff;
$jumbotron-text-regular: $text-regular;
$jumbotron-box-shadow: #e1e1e1;
$jumbotron-text-footer: #859195;
/*End Jumbotron*/

/*PageNav*/
$page-nav-breadcrumbs-color: $light-blue-gray;
$page-nav-title-color: $text-regular;
/*End PageNav*/

/*Site*/
$site-header-meta-bg: #f8f8f8;
$site-header-meta-color: #575757;
$site-header-meta-link-color: #444444;
$site-header-main-color: #979798;
$site-header-main-hover: #273b91;
$site-footer-bg-color: #f2f2f2;
$site-border-color: #e1e1e1;

$site-input-text-color: #777777;
$site-btn-default-color: #41ce0a;

$site-sign-link-blue: #0039DE;
$site-sign-text-blue: #253F93;
$site-sign-orange: #f9AA34;
$site-sign-gray: #575757;
$site-sign-border-color: #EEEEEE;
$site-bg-color: #F1F3F3;
$site-oauth-border-color: #DDDDDD;

$site-google-bg-color: #4285F4;
$site-facebook-bg-color: #4267B2;
$site-azure-bg-color: #1B8DD3;
/*End Site*/

/*Hamburger*/
$hamburger-color: $text-regular;
/*End Hamburger*/

/*Support dialog*/
$ca-ticket-user-color: #aaaaaf;
$ca-ticket-support-color: #eaeaef;
/*End Support dialog*/

/*Billing*/
$billing-status-bg: #ececec;
$payment-forecast-bg: #ebebeb;

/*Alert*/
$alert-bg: #ebebeb;
$alert-link-color: #487685;

/*Block*/
$block-border-color: #e1e1e1;

/*Dashboard - Start Using*/
$start-using-icon-color: $blue-gray;

/*Tooltip*/
$tooltip-border: #a9b3d4;
$tooltip-bg: #ececec;
$tooltip-box-shadow: rgba(0, 0, 0, 0.1);

/*Button*/
$button-shadow: rgba(200, 200, 200, 0.56);
